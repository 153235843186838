<script setup lang="ts">

import {Ref, ref, toRef} from "vue";
import axios from "axios";

const props = defineProps<{
    processing: boolean,
}>();

const processing = toRef(props, 'processing');
const file: Ref<File | null> = ref(null);
const emit = defineEmits(['processing', 'error', 'success'])
//const isProcessing: Ref<boolean> = ref(false);

const onFileChange = (event: any) => {
    file.value = event.target.files[0];
}

const upload = async () => {
    emit('processing');

    const formData = new FormData();
    formData.set('catalog', file.value as Blob);

    try {
        const uploadResponse = await axios.post('/api/catalog/upload', formData);
        emit('success');
        console.log(uploadResponse.data);
    } catch (error) {
        emit('error');
        console.log('Could not upload the catalog');
    }
}

</script>

<template>
<div class="mt-2">
    <label class="block mb-2 text-sm text-gray-500">Update your catalog by uploading an up-to-date file in the predefined format</label>

    <div class="flex">
        <input class="
                    w-[360px]
                    file:py-2.5
                    file:px-4
                    file:me-4
                    file:border-0
                    file:bg-gray-600
                    file:text-white
                    block
                    text-sm
                    border
                    rounded-lg
                    cursor-pointer
                    text-gray-400
                    focus:outline-none
                    bg-gray-700
                    border-gray-600
                    placeholder-gray-400
                "
               type="file"
               accept="text/csv,text/xml"
               @change="onFileChange"
        >

        <button class="
            outline-none
            font-medium
            rounded-lg
            text-sm
            px-4
            py-2.5
            text-center
            inline-flex
            items-center
            text-gray-900
            bg-gray-100
            hover:bg-gray-200
            ml-2
        "
            type="button"
            :disabled="file === null || processing"
            @click.prevent="upload"
        >
            Upload

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" class="w-4 h-4 ml-2" v-if="!processing">
                <path d="M7.25 10.25a.75.75 0 0 0 1.5 0V4.56l2.22 2.22a.75.75 0 1 0 1.06-1.06l-3.5-3.5a.75.75 0 0 0-1.06 0l-3.5 3.5a.75.75 0 0 0 1.06 1.06l2.22-2.22v5.69Z" />
                <path d="M3.5 9.75a.75.75 0 0 0-1.5 0v1.5A2.75 2.75 0 0 0 4.75 14h6.5A2.75 2.75 0 0 0 14 11.25v-1.5a.75.75 0 0 0-1.5 0v1.5c0 .69-.56 1.25-1.25 1.25h-6.5c-.69 0-1.25-.56-1.25-1.25v-1.5Z" />
            </svg>

            <span class="loader ml-2" v-else />

        </button>
    </div>
</div>
</template>
