<template>
    <svg height="100%" viewBox="0 0 96 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M70.8243 17.6959L29.1511 72.3847L-4.59692e-06 62.1698L70.8243 17.6959Z" fill="url(#paint0_linear_3_11)"/>
        <path d="M70.8243 17.6959L29.2139 72.3032L48.1367 99.1932L70.8243 17.6959Z" fill="url(#paint1_linear_3_11)"/>
        <path d="M70.9875 17.8214L29.0695 72.322L12.0483 46.505L70.9875 17.8214Z" fill="url(#paint2_linear_3_11)"/>
        <path d="M70.9875 17.8214L29.0695 72.322L58.3901 82.1478L70.9875 17.8214Z" fill="url(#paint3_linear_3_11)"/>
        <defs>
            <linearGradient id="paint0_linear_3_11" x1="19.9034" y1="54.7229" x2="46.4559" y2="46.5464" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2FAA10"/>
                <stop offset="1" stop-color="#40DE26"/>
            </linearGradient>
            <linearGradient id="paint1_linear_3_11" x1="49.4053" y1="77.4136" x2="51.6922" y2="48.9397" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0CB2D7"/>
                <stop offset="1" stop-color="#60D9FF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_3_11" x1="23.3827" y1="52.3994" x2="48.2018" y2="44.7322" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FE7A00"/>
                <stop offset="1" stop-color="#FFAC60"/>
            </linearGradient>
            <linearGradient id="paint3_linear_3_11" x1="49.7843" y1="72.7057" x2="50.8255" y2="46.7501" gradientUnits="userSpaceOnUse">
                <stop stop-color="#BD2222"/>
                <stop offset="1" stop-color="#F45656"/>
            </linearGradient>
        </defs>
    </svg>
</template>
