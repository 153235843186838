<template>
    <svg height="100%" viewBox="0 0 365 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M71.3636 42V18.7273H78.5341C80.0038 18.7273 81.269 19.0151 82.3296 19.5909C83.3902 20.1667 84.2046 20.9659 84.7727 21.9886C85.3409 23.0038 85.625 24.1856 85.625 25.5341C85.625 26.8674 85.3409 28.0417 84.7727 29.0568C84.2046 30.0644 83.3902 30.8523 82.3296 31.4205C81.2765 31.9886 80.0152 32.2727 78.5455 32.2727H72.125V30.9432H78.5114C79.7083 30.9432 80.7311 30.7197 81.5796 30.2727C82.428 29.8258 83.0796 29.2008 83.5341 28.3977C83.9886 27.5871 84.2159 26.6326 84.2159 25.5341C84.2159 24.428 83.9886 23.4659 83.5341 22.6477C83.0796 21.822 82.4243 21.1818 81.5682 20.7273C80.7197 20.2727 79.697 20.0455 78.5 20.0455H72.7841V42H71.3636ZM80.9205 31.4545L86.6477 42H85.0114L79.3182 31.4545H80.9205Z" fill="#DFDFDF"/>
        <path d="M95.1411 42V18.7273H108.096V20.0455H96.5616V29.6932H107.403V31.0114H96.5616V40.6818H108.368V42H95.1411Z" fill="#DFDFDF"/>
        <path d="M135.552 26H134.098C133.946 25.2121 133.662 24.4508 133.245 23.7159C132.829 22.9735 132.291 22.3068 131.632 21.7159C130.98 21.1174 130.223 20.6439 129.359 20.2955C128.495 19.947 127.541 19.7727 126.495 19.7727C124.965 19.7727 123.556 20.1818 122.268 21C120.988 21.8106 119.961 23.0038 119.189 24.5795C118.423 26.1553 118.041 28.0833 118.041 30.3636C118.041 32.6591 118.423 34.5947 119.189 36.1705C119.961 37.7462 120.988 38.9394 122.268 39.75C123.556 40.553 124.965 40.9545 126.495 40.9545C127.541 40.9545 128.495 40.7841 129.359 40.4432C130.223 40.0947 130.98 39.625 131.632 39.0341C132.291 38.4356 132.829 37.7652 133.245 37.0227C133.662 36.2803 133.946 35.5151 134.098 34.7273H135.552C135.386 35.6667 135.064 36.5871 134.586 37.4886C134.117 38.3901 133.495 39.2083 132.723 39.9432C131.957 40.6705 131.052 41.25 130.007 41.6818C128.969 42.1061 127.798 42.3182 126.495 42.3182C124.556 42.3182 122.848 41.8258 121.37 40.8409C119.893 39.8485 118.734 38.4545 117.893 36.6591C117.06 34.8636 116.643 32.7651 116.643 30.3636C116.643 27.9621 117.06 25.8636 117.893 24.0682C118.734 22.2727 119.893 20.8826 121.37 19.8977C122.848 18.9053 124.556 18.4091 126.495 18.4091C127.798 18.4091 128.969 18.625 130.007 19.0568C131.052 19.4811 131.957 20.0568 132.723 20.7841C133.495 21.5038 134.117 22.3144 134.586 23.2159C135.064 24.1174 135.386 25.0455 135.552 26Z" fill="#DFDFDF"/>
        <path d="M163.364 30.3636C163.364 32.7651 162.947 34.8636 162.114 36.6591C161.28 38.447 160.121 39.8371 158.636 40.8295C157.159 41.822 155.455 42.3182 153.523 42.3182C151.583 42.3182 149.871 41.822 148.386 40.8295C146.909 39.8295 145.754 38.4356 144.921 36.6477C144.087 34.8523 143.671 32.7576 143.671 30.3636C143.671 27.9621 144.087 25.8674 144.921 24.0795C145.761 22.2917 146.921 20.9015 148.398 19.9091C149.875 18.9091 151.583 18.4091 153.523 18.4091C155.455 18.4091 157.159 18.9053 158.636 19.8977C160.121 20.8901 161.28 22.2841 162.114 24.0795C162.947 25.8674 163.364 27.9621 163.364 30.3636ZM161.977 30.3636C161.977 28.2045 161.614 26.3333 160.886 24.75C160.167 23.1667 159.171 21.9432 157.898 21.0795C156.625 20.2083 155.167 19.7727 153.523 19.7727C151.879 19.7727 150.421 20.2045 149.148 21.0682C147.875 21.9242 146.875 23.1477 146.148 24.7386C145.428 26.322 145.068 28.197 145.068 30.3636C145.068 32.5076 145.424 34.375 146.136 35.9659C146.856 37.5492 147.852 38.7765 149.125 39.6477C150.398 40.5189 151.864 40.9545 153.523 40.9545C155.167 40.9545 156.625 40.5227 157.898 39.6591C159.171 38.7879 160.167 37.5606 160.886 35.9773C161.614 34.3939 161.977 32.5227 161.977 30.3636Z" fill="#DFDFDF"/>
        <path d="M172.755 18.7273H174.3L183.266 39.9545H183.425L192.391 18.7273H193.937V42H192.573V22.1023H192.437L184.016 42H182.675L174.255 22.1023H174.119V42H172.755V18.7273Z" fill="#DFDFDF"/>
        <path d="M204.501 18.7273H206.047L215.013 39.9545H215.172L224.138 18.7273H225.683V42H224.319V22.1023H224.183L215.763 42H214.422L206.001 22.1023H205.865V42H204.501V18.7273Z" fill="#DFDFDF"/>
        <path d="M236.247 42V18.7273H249.202V20.0455H237.668V29.6932H248.509V31.0114H237.668V40.6818H249.475V42H236.247Z" fill="#DFDFDF"/>
        <path d="M258.931 42V18.7273H266.102C267.571 18.7273 268.836 19.0151 269.897 19.5909C270.958 20.1667 271.772 20.9659 272.34 21.9886C272.908 23.0038 273.193 24.1856 273.193 25.5341C273.193 26.8674 272.908 28.0417 272.34 29.0568C271.772 30.0644 270.958 30.8523 269.897 31.4205C268.844 31.9886 267.583 32.2727 266.113 32.2727H259.693V30.9432H266.079C267.276 30.9432 268.299 30.7197 269.147 30.2727C269.996 29.8258 270.647 29.2008 271.102 28.3977C271.556 27.5871 271.783 26.6326 271.783 25.5341C271.783 24.428 271.556 23.4659 271.102 22.6477C270.647 21.822 269.992 21.1818 269.136 20.7273C268.287 20.2727 267.264 20.0455 266.068 20.0455H260.352V42H258.931ZM268.488 31.4545L274.215 42H272.579L266.886 31.4545H268.488Z" fill="#DFDFDF"/>
        <path d="M300.436 26H298.981C298.83 25.2121 298.546 24.4508 298.129 23.7159C297.712 22.9735 297.175 22.3068 296.515 21.7159C295.864 21.1174 295.106 20.6439 294.243 20.2955C293.379 19.947 292.425 19.7727 291.379 19.7727C289.849 19.7727 288.44 20.1818 287.152 21C285.872 21.8106 284.845 23.0038 284.072 24.5795C283.307 26.1553 282.925 28.0833 282.925 30.3636C282.925 32.6591 283.307 34.5947 284.072 36.1705C284.845 37.7462 285.872 38.9394 287.152 39.75C288.44 40.553 289.849 40.9545 291.379 40.9545C292.425 40.9545 293.379 40.7841 294.243 40.4432C295.106 40.0947 295.864 39.625 296.515 39.0341C297.175 38.4356 297.712 37.7652 298.129 37.0227C298.546 36.2803 298.83 35.5151 298.981 34.7273H300.436C300.269 35.6667 299.947 36.5871 299.47 37.4886C299 38.3901 298.379 39.2083 297.606 39.9432C296.841 40.6705 295.936 41.25 294.89 41.6818C293.853 42.1061 292.682 42.3182 291.379 42.3182C289.44 42.3182 287.731 41.8258 286.254 40.8409C284.777 39.8485 283.618 38.4545 282.777 36.6591C281.944 34.8636 281.527 32.7651 281.527 30.3636C281.527 27.9621 281.944 25.8636 282.777 24.0682C283.618 22.2727 284.777 20.8826 286.254 19.8977C287.731 18.9053 289.44 18.4091 291.379 18.4091C292.682 18.4091 293.853 18.625 294.89 19.0568C295.936 19.4811 296.841 20.0568 297.606 20.7841C298.379 21.5038 299 22.3144 299.47 23.2159C299.947 24.1174 300.269 25.0455 300.436 26Z" fill="#DFDFDF"/>
        <path d="M309.736 42V18.7273H322.691V20.0455H311.157V29.6932H321.998V31.0114H311.157V40.6818H322.963V42H309.736Z" fill="#DFDFDF"/>
        <path d="M335.568 18.7273V42H331.352V18.7273H335.568Z" fill="#DFDFDF"/>
        <path d="M352.643 34.1818H356.462L358.723 37.1136L360.337 39.0227L364.212 44H360.121L357.484 40.6591L356.359 39.0682L352.643 34.1818ZM364.768 30.3636C364.768 32.8712 364.299 35.0189 363.359 36.8068C362.427 38.5871 361.155 39.9508 359.541 40.8977C357.935 41.8447 356.113 42.3182 354.075 42.3182C352.037 42.3182 350.212 41.8447 348.598 40.8977C346.992 39.9432 345.719 38.5758 344.78 36.7955C343.848 35.0076 343.382 32.8636 343.382 30.3636C343.382 27.8561 343.848 25.7121 344.78 23.9318C345.719 22.1439 346.992 20.7765 348.598 19.8295C350.212 18.8826 352.037 18.4091 354.075 18.4091C356.113 18.4091 357.935 18.8826 359.541 19.8295C361.155 20.7765 362.427 22.1439 363.359 23.9318C364.299 25.7121 364.768 27.8561 364.768 30.3636ZM360.53 30.3636C360.53 28.5985 360.253 27.1098 359.7 25.8977C359.155 24.678 358.397 23.7576 357.427 23.1364C356.458 22.5076 355.34 22.1932 354.075 22.1932C352.81 22.1932 351.693 22.5076 350.723 23.1364C349.753 23.7576 348.992 24.678 348.439 25.8977C347.893 27.1098 347.621 28.5985 347.621 30.3636C347.621 32.1288 347.893 33.6212 348.439 34.8409C348.992 36.053 349.753 36.9735 350.723 37.6023C351.693 38.2235 352.81 38.5341 354.075 38.5341C355.34 38.5341 356.458 38.2235 357.427 37.6023C358.397 36.9735 359.155 36.053 359.7 34.8409C360.253 33.6212 360.53 32.1288 360.53 30.3636Z" fill="#DFDFDF"/>
        <path d="M63.968 0L20.9038 34.9346L0 21.3835L63.968 0Z" fill="url(#paint0_linear_110_45)"/>
        <path d="M63.968 0L20.9685 34.8826L30.684 59.5571L63.968 0Z" fill="url(#paint1_linear_110_45)"/>
        <path d="M64.072 0.129403L20.8518 34.8699L12.4226 11.3982L64.072 0.129403Z" fill="url(#paint2_linear_110_45)"/>
        <path d="M64.072 0.129403L20.8518 34.8699L41.9625 48.1483L64.072 0.129403Z" fill="url(#paint3_linear_110_45)"/>
        <defs>
            <linearGradient id="paint0_linear_110_45" x1="24.5427" y1="45.8605" x2="30.5476" y2="21.4837" gradientUnits="userSpaceOnUse">
                <stop stop-color="#2FAA10"/>
                <stop offset="1" stop-color="#40DE26"/>
            </linearGradient>
            <linearGradient id="paint1_linear_110_45" x1="24.8038" y1="45.8605" x2="30.7526" y2="21.4545" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0CB2D7"/>
                <stop offset="1" stop-color="#60D9FF"/>
            </linearGradient>
            <linearGradient id="paint2_linear_110_45" x1="27.5692" y1="48.8812" x2="34.7133" y2="23.3188" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FE7A00"/>
                <stop offset="1" stop-color="#FFAC60"/>
            </linearGradient>
            <linearGradient id="paint3_linear_110_45" x1="37.0139" y1="39.1111" x2="42.7673" y2="18.9667" gradientUnits="userSpaceOnUse">
                <stop stop-color="#BD2222"/>
                <stop offset="1" stop-color="#F45656"/>
            </linearGradient>
        </defs>
    </svg>

</template>
