<script setup lang="ts">

import {AlertMessage} from "../types";
import {onUpdated, toRef} from "vue";

const props = defineProps<{
    message: AlertMessage | null;
}>();

const message = toRef(props, 'message');

onUpdated(() => {
    window.setTimeout(() => {
        message.value = null;
    }, 1200);
});


</script>


<template>

    <transition enter-from-class="translate-x-10 opacity-0" enter-to-class="translate-x-0 opacity-100" leave-from-class="translate-x-0 opacity-100" leave-to-class="translate-x-6 opacity-0" >

        <div v-if="message" class="transition-all duration-500 ease-in-out shadow fixed top-20 right-5 flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white rounded-lg dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">

            <div v-if="message.type === 'success'" class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
            </div>

            <div v-if="message.type === 'error'" class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </div>

            <div class="ml-3 text-sm font-normal">{{ message.message }}</div>
        </div>

    </transition>

</template>
