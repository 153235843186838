<script setup lang="ts">
import { ref, defineProps } from 'vue';

type Filter = {
    label: string,
    value: string,
}
const props = defineProps<{
    value: string[],
    options: Filter[],
    show: boolean,
    buttonLabel?: string,
    optionsLabel?: string,
}>();

const emit = defineEmits(['update:value']);
const opened = ref(false);

const onChange = (optionValue: string, event: Event) => {
    const updatedValue = [...props.value];
    const target = event.target as HTMLInputElement;

    if (!target) return;

    if (target.checked) {
        updatedValue.push(optionValue);
    } else {
        updatedValue.splice(updatedValue.indexOf(optionValue), 1);
    }

    emit('update:value', updatedValue);
}
</script>


<template>
    <div v-if="props.show">
        <div class="relative" v-click-outside="() => opened = false">
            <button
                @click="opened = true"

                class="text-white outline-none font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center bg-blue-600 hover:bg-blue-700" type="button"
            >
                {{ props.buttonLabel }}
                <svg class="w-4 h-4 ml-2" aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                </svg>
            </button>

            <transition
                enter-from-class="-translate-y-6 opacity-0 scale-75"
                enter-to-class="translate-y-0 opacity-100 scale-100"
                leave-from-class="translate-y-0 opacity-100 scale-100"
                leave-to-class="-translate-y-6 opacity-0 scale-75" >
                <div v-if="opened" class="absolute z-10 top-12 -left-4 w-96 p-3 rounded-lg shadow bg-gray-700 shadow-lg">
                    <h6 class="mb-3 text-xs font-medium text-gray-900 dark:text-white">{{ props.optionsLabel }}</h6>

                    <ul class="space-y-2 text-sm">
                        <li class="flex items-center" v-for="option in options">
                            <input
                                type="checkbox"
                                :id="option.value"
                                :value="option.value"
                                :checked="props.value.includes(option.value)"
                                @change="onChange(option.value, $event)"
                                class="w-4 h-4 rounded text-primary-600 focus:ring-primary-600 ring-offset-gray-700 focus:ring-2 bg-gray-600 border-gray-500"
                            />
                            <label :for="option.value" class="ml-2 text-sm font-medium text-gray-100 truncate ...">
                                {{ option.label }}
                            </label>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>
